import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Language} from './shared/config/language';
import siteSettings from './shared/config/site-settings';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-root',
  template: '<ngx-spinner></ngx-spinner><router-outlet></router-outlet>',
  styles: ['']
})
export class AppComponent implements OnInit {

  constructor(private translate: TranslateService,
              private activatedRoute: ActivatedRoute) {
    translate.setDefaultLang('kk');
  }

  ngOnInit(): void {


    console.log('updated:14.01.2021');
    const language: number = JSON.parse(localStorage.getItem('eJambylLanguage'));
    let lang = 'kk';

    if (language) {
      if (language === Language.KAZ) {
        lang = 'kk';
      }
      if (language === Language.ENG) {
        lang = 'en';
      }
    }

    this.translate.use(lang);

    siteSettings.language = language ? language : Language.KAZ;
  }
}
