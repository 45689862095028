<h2 mat-dialog-title></h2>
<mat-dialog-content class="mat-typography">

  <mat-form-field fxFlex="100%" appearance="outline">
    <mat-label>Масштаб</mat-label>
    <input matInput type="number" [(ngModel)]="scale" name="scale" max="559082264">
  </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="ok()">{{'button.ok' | translate}}</button>
  <button mat-button mat-dialog-close>{{'button.close' | translate}}</button>
</mat-dialog-actions>
