import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MapService} from 'src/app/shared/services/map.service';
import * as L from 'leaflet';
import siteSettings from '../../shared/config/site-settings';
import {LayersManagerService} from '../../shared/services/layers-manager.service';
import html2canvas from 'html2canvas';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ScaleDialogComponent} from '../scale-dialog/scale-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-zoom-in-out',
  templateUrl: './zoom-in-out.component.html',
  styleUrls: ['./zoom-in-out.component.scss'],
  providers: [DatePipe]
})
export class ZoomInOutComponent implements OnInit {
  toolsShow = false;
  copyBody: any;
  scale;
  maxScale = 559082264;
  maxZoom = 0;
  currentZoom = 12;
  lastZoom = 12;

  constructor(private mapSvc: MapService,
              private spinner: NgxSpinnerService,
              public dialog: MatDialog,
              @Inject(DOCUMENT) private dom: Document,
              private translateService: TranslateService,
              private datePipe: DatePipe,
              private layersManagerService: LayersManagerService) {
  }

  ngOnInit(): void {

    this.mapSvc.zoomEnd.subscribe(res => {

      setTimeout(() => {
        this.printMaps();
      }, 2000);

    });
  }

  changeZoom(state: boolean): void {
    const map = this.mapSvc.getMap();
    let zoomLevel = map.getZoom();
    map.setZoom(state ? ++zoomLevel : --zoomLevel);
  }

  extentZoom(): void {
    const map = this.mapSvc.getMap();
    map.setView([42.896, 71.393], 12);
  }

  locateUser(): void {
    const map = this.mapSvc.getMap();
    if (navigator.geolocation) {
      this.mapSvc.clearEditableLayers();
      navigator.geolocation.getCurrentPosition((position) => {
        L.circle([position.coords.latitude, position.coords.longitude], {
          radius: 29,
          color: 'blue',
        }).addTo(this.mapSvc.editableLayers);
        map.setView([position.coords.latitude, position.coords.longitude], 17);
      });
    } else {
      alert('Ваш браузер не поддерживает HTML5 Geolocation');
    }
  }

  // clearLayers(): void {
  //   // this.layersManagerService.addLayer('crime', '1=1');
  //   this.layersManagerService.clearSearchMarkerByAddressRegister();
  //   this.layersManagerService.clearEditableLayers();
  //   siteSettings.isClearAllLyrs = true;
  //   // this.layersManagerService.removeAllVisibleLayers();
  // }

  tools(): void {
    this.toolsShow = !this.toolsShow;

    const measure = document.querySelector('div.leaflet-control-measure') as HTMLDivElement;
    const rule = document.querySelector('div.leaflet-ruler') as HTMLDivElement;
    if (this.toolsShow) {
      measure.style.display = 'block';
      rule.style.display = 'block';
    } else {
      measure.style.display = 'none';
      rule.style.display = 'none';
    }

  }

  print(): void {
    this.setScale();
    // this.printMaps();
  }

  printMaps(): void {

    const body = document.querySelector('body') as HTMLBodyElement;
    const bodyHeight = window.innerHeight;
    const bodyWidth = window.innerWidth;

    const head = document.querySelector('head') as HTMLHeadElement;
    const map = document.querySelector('div.leaflet-container') as HTMLDivElement;
    const offsetWidth = map.clientWidth;
    const offsetHeight = map.clientHeight;

    const WindowPrt = window.open('', '', `width=${bodyWidth + 50},height=${bodyHeight + 50},toolbar=0,scrollbars=0,status=0`);
    WindowPrt.document.write(head.innerHTML);
    WindowPrt.document.write(map.innerHTML);
    WindowPrt.document.write(this.getPrintDescription(offsetWidth, offsetHeight));
    WindowPrt.document.close();
    WindowPrt.focus();
    setTimeout(() => {
      WindowPrt.print();
      this.spinner.hide();
      WindowPrt.close();
      siteSettings.print = false;
    }, 2000);


  }

  printMaps2(): void {

    let body = document.querySelector('body') as HTMLBodyElement;
    this.copyBody = body.cloneNode();
    const map = document.querySelector('div.leaflet-container') as HTMLDivElement;
    body.innerHTML = map.innerHTML;
    setTimeout(() => {
      window.print();
      body = this.copyBody;
    }, 100);

    // body.innerHTML = this.copyBody;
    // document.location.reload();
  }

  //
  // printMaps(): void {
  //   const body = document.querySelector('body') as HTMLBodyElement;
  //   const mapContainer = document.querySelector('div.leaflet-container') as any;
  //   const mapContainerParent = mapContainer.parent;
  //   const printContainer = document.createElement('div') as HTMLDivElement;
  //   printContainer.className = 'print-container';
  //   printContainer.style.position = 'relative';
  //   printContainer.style.height = mapContainer.clientHeight;
  //   printContainer.style.width = mapContainer.clientWidth;
  //
  //   body.append(printContainer);
  //   const content = body.innerHTML;
  //   /* .detach(); */
  //
  //   /*
  //    * Needed for those who use Bootstrap 3.x, because some of
  //    * its `@media print` styles ain't play nicely when printing.
  //    */
  //   const patchedStyle = document.createElement('style') as HTMLStyleElement;
  //   patchedStyle.media = 'print';
  //   patchedStyle.innerText = 'img { max-width: none !important; } a[href]:after { content: ""; }';
  //
  //
  //   window.print();
  //
  //   body.prepend(content);
  //   mapContainerParent.prepend(mapContainer);
  //
  //   printContainer.remove();
  //   patchedStyle.remove();
  // };

  setScale(): void {

    const dialogRef = this.dialog.open(ScaleDialogComponent, {width: '300px'});
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.scale = result;
        this.setZoomByScale();
        const map = this.mapSvc.getMap();
        // this.spinner.show();
        siteSettings.print = true;
        if (this.currentZoom !== this.lastZoom) {
          this.spinner.show();
          this.lastZoom = this.currentZoom;
          map.setZoom(this.currentZoom);
        } else {
          this.spinner.show();
          this.printMaps();
        }

      }
    });
  }

  @HostListener('window:afterprint')
  // tslint:disable-next-line:typedef
  onafterprint() {
    let body = document.querySelector('body') as HTMLBodyElement;
    body = this.copyBody;
    // document.location.reload();
  }

  screen(): void {
    this.spinner.show();
    (document.querySelector('.leaflet-control-container') as HTMLElement).style.display = 'none';
    (document.querySelector('.leaflet-right') as HTMLElement).style.display = 'none';
    // (document.querySelector('app-right-header') as HTMLElement).style.display = 'none';
    const mapContainer = document.getElementById('mapid') as HTMLDivElement;
    html2canvas(mapContainer, {
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const data = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const element = document.createElement('a');
      element.setAttribute('href', data);
      element.setAttribute('download', 'map.png');
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
      this.spinner.hide();
    });
    (document.querySelector('.leaflet-control-container') as HTMLElement).style.display = 'block';
    (document.querySelector('.leaflet-right') as HTMLElement).style.display = 'block';
    // (document.querySelector('app-right-header') as HTMLElement).style.display = 'block';

  }

  measureDistance(): void {
  }

  measureArea(): void {
  }

  getCoordinates(): void {
    siteSettings.isCoordinate = true;
  }

  setZoomByScale(): void {

    if (this.maxScale < this.scale) {
      this.currentZoom = 0;
    } else {
      let sc = this.maxScale / 2;
      this.currentZoom = 1;
      while (sc > this.scale) {
        this.currentZoom++;
        sc = sc / 2;
      }

    }
  }

  getPrintDescription(offsetWidth, offsetHeight): string {

    let title = '';
    this.translateService.get('print.title').subscribe(res => {
      title = res;
    });

    let scaleMM = '';
    this.translateService.get('print.scaleMM').subscribe(res => {
      scaleMM = res;
    });

    let scale = '';
    this.translateService.get('print.scale').subscribe(res => {
      scale = res;
    });

    let date = '';
    this.translateService.get('print.date').subscribe(res => {
      date = res;
    });

    let url = '';
    this.translateService.get('print.url').subscribe(res => {
      url = res;
    });

    const w = Math.floor(offsetWidth * 0.264583);
    const h = Math.floor(offsetHeight * 0.264583);

    const currDate = new Date();
    const currDateStr = this.datePipe.transform(currDate, 'dd.MM.yyyy HH:mm');
    offsetHeight += 300;
    const html = `<div style="display:block;top:${offsetHeight}; position:absolute;top:bottom: 0px;left:0">`
      + `<p style="font-size: 14px;"><b>${title}</b></p>`
      + `<p>${scaleMM}: ${w}mm x ${h}mm</p>`
      + `<p>${scale}: 1:${this.scale}</p>`
      + `<p>${date}: ${currDateStr}</p>`
      + `<p>${url}: <a href="https://geo.e-jambyl.kz/layer-themes">geo.e-jambyl.kz</a></p>`
      + '</div>';

    return html;
  }
}
