<h2 mat-dialog-title></h2>
<mat-dialog-content class="mat-typography">
  <!-- KAZ -->
  <div *ngIf="siteSettings.language===language.KAZ">
    <p><strong>Жобаның мақсаты</strong> - Тараз қаласының тұрғындарына электронды-анықтамалық карталарға қолжетімділікті
      қамтамасыз ету.</p>
    <p>Тараз қаласының Геопорталының ашық картасында әлеуметтік инфрақұрылым объектілері белгіленген, қаланың әртүрлі
      ұйымдары мен мекемелері байланыс деректері бар мәліметтер: ХҚКО, ауруханалар, дәріханалар, банктер, банкоматтар
      және басқалар.</p>
    <p>Картада сауда-саттыққа қатысу шарттары және өтінімдерді қабылдау орындары сипатталған, алдағы өтетін жер
      сауда-саттығы туралы ақпарат мерзімді түрде жарияланатын болады.</p>
    <p>Әрбір тілек білдіруші қаланы дамытудың Бас жоспарымен, егжей-тегжейлі жоспарлау жобаларымен, оның ішінде қаланың
      қолданыстағы және жобаланатын бөлігіндегі қызыл сызықтармен таныса алады.&nbsp;</p>
    <p>Нысанды устінен басу арқылы сіз таңдалған нысан туралы ақпарат ала аласыз.</p>
    <p>Геопортал бойынша қосымша ақпаратты мына мекен-жай бойынша ала аласыз: &laquo;Тараз қаласының сәулет және қала
      құрылысы бөлімі&raquo; КММ&raquo;</p>
    <p>080012 Тараз қ., М. Жүнісалиев к-сі, 1 үй</p>
    <p>8 (7262) 45-54-97, 8 (7262) 43-64-78</p>
  </div>

  <!-- RUS -->
  <div *ngIf="siteSettings.language===language.RUS || siteSettings.language===language.ENG">
    <p><strong>Цель проекта</strong> &ndash; предоставление доступа населению к электронно-справочным картам г. Тараз.
    </p>
    <p>На Открытой карте Геопортала г. Тараз отмечены объекты социальной инфраструктуры, это различные организаций и
      учреждения города с их контактными данными: ЦОНы, больницы, аптеки, банки, банкоматы и другие.</p>
    <p>На карте периодично будет публиковаться информация о предстоящих земельных торгах, с подробным описанием условий
      участия на торгах и местами приема заявок.</p>
    <p>Каждый желающий может ознакомиться с генеральным планом развития города, с проектами детальных планировок в том
      числе с красными линиями в существующей и проектируемой части города.</p>
    <p>При клике на объект, вы сможете получить информацию о выбранном объекте.</p>
    <p>Дополнительную информацию по Геопорталу, вы сможете получить по адресу: КГУ &laquo;Отдел архитектуры и
      градостроительства г. Тараз&raquo;</p>
    <p>080012 г. Тараз, ул. Жунисалиева М, Дом 1</p>
    <p>8 (7262) 45-54-97, 8 (7262) 43-64-78</p>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'bottom.close' | translate}}</button>
</mat-dialog-actions>
