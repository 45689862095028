import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {environment} from '../../../environments/environment';
import {ILayerGroup} from '../models';
import {
  IAddress,
  IAuctionFilter,
  ICompetitionFilter,
  ICrash,
  ICrime,
  ICrimeFilter,
  ICrimeHard,
  IDtp,
  ILandTenderStatus
} from '../portal.models';

import {ajax} from 'rxjs/ajax';

@Injectable({
  providedIn: 'root'
})
export class PortalApiService {

  currentLayerGroupId$ = new BehaviorSubject<ILayerGroup>(null);
  auction = new Subject();

  constructor(private http: HttpClient) {
  }

  /* Справочник организаций */
  getSocialObjects(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/social-objects/types`);
  }

  getSocialObjectsByTypeId(typeId): Observable<any> {
    return this.http.get<any>(`${environment.api}/social-objects?page=0&size=500&sort=gid,DESC&type=${typeId}`);
  }

  /* Земельные торги */
  getAuctionAddress(): Observable<IAddress[]> {
    return this.http.get<IAddress[]>(`${environment.api}/auction-lands/address`);
  }

  getAuctionStatus(): Observable<ILandTenderStatus[]> {
    return this.http.get<ILandTenderStatus[]>(`${environment.api}/auction-lands/statuses`);
  }

  getAllAuctions(filter: IAuctionFilter): Observable<any> {

    let sqlWhere = 'page=0&size=1000000';
    if (filter.startDate && filter.startDate !== null && filter.startDate !== '') {
      sqlWhere += `&startDate=${filter.startDate}`;
    }

    if (filter.endDate && filter.endDate !== null && filter.endDate !== '') {
      sqlWhere += `&endDate=${filter.endDate}`;
    }

    sqlWhere += '&sort=auction_time,ASC';

    return this.http.post<any>(`${environment.api}/auction-lands/all?${sqlWhere}`, {
      address_id: null,
      auction_land_status_id: filter.auction_land_status_id
    });
  }

  /* competitions */
  getCompetitionsAddress(): Observable<IAddress[]> {
    return this.http.get<IAddress[]>(`${environment.api}/competitions/address`);
  }

  getCompetitionsStatus(): Observable<ILandTenderStatus[]> {
    return this.http.get<ILandTenderStatus[]>(`${environment.api}/competitions/statuses`);
  }

  getAllCompetitions(filter: ICompetitionFilter): Observable<any> {
    let sqlWhere = 'page=0&size=20';
    if (filter.startDate && filter.startDate !== null && filter.startDate !== '') {
      sqlWhere += `&startDate=${filter.startDate}`;
    }

    if (filter.endDate && filter.endDate !== null && filter.endDate !== '') {
      sqlWhere += `&endDate=${filter.endDate}`;
    }

    sqlWhere += '&sort=competition_time,ASC';
    return this.http.post<any>(`${environment.api}/competitions/all?${sqlWhere}`, {
      address_id: filter.address_id,
      status_id: filter.status_id
    });
  }

  /* правонарушения(crime) */

  // Получить виды правонарушений
  getCrimeTypes(): Observable<ICrime[]> {
    return this.http.get<ICrime[]>(`${environment.api}/crime?sort=code,asc`); // ?sort=id,desc
  }

  // Тяжесть преступлении
  getCrimeHard(): Observable<ICrimeHard[]> {
    return this.http.get<ICrimeHard[]>(`${environment.api}/crime/hard`);
  }

  // Получить правонарушения
  getCrimes(filter: ICrimeFilter): Observable<any> {
    let sqlWhere = 'page=0&size=10000';
    if (filter.startDate && filter.startDate !== null && filter.startDate !== '') {
      sqlWhere += `&startDate=${filter.startDate}`;
    }

    if (filter.endDate && filter.endDate !== null && filter.endDate !== '') {
      sqlWhere += `&endDate=${filter.endDate}`;
    }

    sqlWhere += '&sort=dat_sover,DESC';

    let hardCode = null;
    if (filter.hardCode && filter.hardCode !== null && filter.hardCode !== '' && filter.hardCode !== '0') {
      hardCode = filter.hardCode;
    }

    let crimeCode = null;
    if (filter.crimeCode && filter.crimeCode !== null && filter.crimeCode !== '' && filter.crimeCode !== '0') {
      crimeCode = filter.crimeCode;
    }

    return this.http.post<any>(`${environment.api}/crime?${sqlWhere}`, {
      hardCode,
      crimeCode
    });
  }


  getCrimeFromGeoserver(cqlFilter): Observable<any> {
    const url = environment.geoserver + `/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=crime
                &outputFormat=application%2Fjson&CQL_FILTER=${cqlFilter}`;
    return this.http.get<any>(url);
  }

  /* Карат ДТП*/

  // Получить нарушения ПДД
  getCrash(): Observable<ICrash[]> {
    return this.http.get<ICrash[]>(`${environment.api}/crime/crash`);
  }

  // Получить ДТП
  getDTP(dtp, startDate, endDate): Observable<IDtp[]> {

    let sqlWhere = `dtp=${dtp}`;
    if (startDate !== null && startDate !== '' && startDate !== undefined) {
      sqlWhere += `&startDate=${startDate}`;
    }

    if (endDate !== null && endDate !== '' && endDate !== undefined) {
      sqlWhere += `&endDate=${endDate}`;
    }

    return this.http.get<IDtp[]>(`${environment.api}/crime/dtp?${sqlWhere}`);
  }

  getDTPFromGeoserver(cqlFilter): Observable<any> {
    const url = environment.geoserver + `/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=trafic_accident
                &outputFormat=application%2Fjson&CQL_FILTER=${cqlFilter}`;
    return this.http.get<any>(url);
  }

  /* Свободные Земельные участки */
  getFreeLands(): Observable<any> {
    return this.http.post<any>(`${environment.api}/free-lands/all?page=0&size=1000`, {});
    // return this.http.get<any>(`${environment.api}/free-lands?addressId=${addressId}&page=0&size=1000`);
  }

  getFeatureInfoWMS(params): Observable<any> {
    return this.http.get<any>(environment.geoserver + `/wfs?${params}`);
  }

  /* Поиск в адресном регистре */
  searchByAddressRegister(searchText): Observable<any> {
    return this.http.post<any>(`${environment.geoapi}/filter?page=0&size=1000&name=${searchText}`, {});
  }

  searchByAddressRegisterOld(searchText): Observable<any> {
    return this.http.get<any>(`${environment.geoapi}?page=0&size=1000&name=${searchText}`);
  }

  ajaxExample(url): void {
    const users = ajax.getJSON(url);
    const subscribe = users.subscribe(
      res => console.log('ajax', res),
      err => console.error('error ajax', err)
    );
  }

  requestExample(url): void {
    this.requestMethod(
      'POST',
      'https://jsonplaceholder.typicode.com/posts',
      JSON.stringify({
        userId: 1,
        title: 'Lorem ipsum',
        body: 'Lorem ipsum dolor sit amet'
      }),
      {
        'Content-Type': 'application/json'
      }
    )
      .then((response) => {
        // console.log('Request suceeded', response);
        // console.log('json2', response.json);
        // console.log('json', response.json());
      })
      .catch((response) => {
        console.log('Request failed', response);
      });
  }

  requestMethod(method, url, body, headers = {}): any {
    const request = new XMLHttpRequest();
    request.open(method, url);
    Object.keys(headers)
      .forEach(key => {
        request.setRequestHeader(key, headers[key]);
      });
    request.send(body);
    return new Promise((resolve, reject) => {
      request.addEventListener('load', response => {
        resolve(response);
      });
      request.addEventListener('error', response => {
        reject(response);
      });
    });
  }

  fetchPostExample(): void {

    fetch(
      'https://jsonplaceholder.typicode.com/posts',
      {
        method: 'POST',
        // mode: 'no-cors',
        // credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: 1,
          title: 'Lorem ipsum',
          body: 'Lorem ipsum dolor sit amet'
        })
      }
    )
      .then((response) => {
        if (response.ok) {
          return Promise.resolve(response.json())
        } else {
          return Promise.reject({
            status: response.status,
            statusText: response.statusText
          })
        }
      })
      .then((data) => {
        console.log('Request suceeded', data);
      })
      .catch((reason) => {
        console.log('Request failed', reason);
      });
  }
}
