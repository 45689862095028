import {Injectable} from '@angular/core';

import * as L from 'leaflet';
import 'leaflet-draw';
import {Subject} from 'rxjs';

// import

// import {GeoSearchControl, SearchControl} from 'leaflet-geosearch/dist';

import '../../../libs/leaflet/measure-path/leaflet-measure-path.js';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private map: L.Map;
  currentLayer: L.TileLayer;
  public editableLayers: L.FeatureGroup = new L.FeatureGroup();

  private drawActivated = false;
  private drawControls: L.Control.Draw = null;
  zoomEnd = new Subject();

  googleRoadLayer = L.tileLayer(
    'http://mt0.google.com/vt/lyrs=m&hl=ru&x={x}&y={y}&z={z}&s=Galileo',
    {
      maxZoom: 21,
      // minZoom: 7,
      attribution: ``,
    }
  );
  googleHybridLayer = L.tileLayer(
    'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
    {
      maxZoom: 21,
      // minZoom: 7,
      attribution: ``,
    }
  );
  osmLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 21,
    // minZoom: 7,
    attribution: ``,
  });
  googleSat = L.tileLayer('http://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
    maxZoom: 21,
    // minZoom: 7,
    attribution: ``
  });
  
  public whiteMap = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoieWVyZG9zMDkiLCJhIjoiY2t6dmNvM3IzMDFtdDJvczluaWQwdDA3ZSJ9.vVQaZe-qndSqHhg3Y1570A', {
    attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
    tileSize: 512,
    maxZoom: 22,
    minZoom: 7,
    zoomOffset: -1,
    // crossOrigin: true,
    id: 'mapbox/light-v11',
    accessToken: 'pk.eyJ1IjoieWVyZG9zMDkiLCJhIjoiY2t6dmNvM3IzMDFtdDJvczluaWQwdDA3ZSJ9.vVQaZe-qndSqHhg3Y1570A'
  });

  public openstreetmap = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoieWVyZG9zMDkiLCJhIjoiY2t6dmNvM3IzMDFtdDJvczluaWQwdDA3ZSJ9.vVQaZe-qndSqHhg3Y1570A', {
    attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
    tileSize: 512,
    maxZoom: 22,
    minZoom: 7,
    zoomOffset: -1,
    id: 'mapbox/streets-v11',
    accessToken: 'pk.eyJ1IjoieWVyZG9zMDkiLCJhIjoiY2t6dmNvM3IzMDFtdDJvczluaWQwdDA3ZSJ9.vVQaZe-qndSqHhg3Y1570A'
  });
  


  constructor() {
  }

  getMap(): L.Map {
    // this.map.on('click', (e: any) => {
    //   this.layersManagerService.showGetFeatureInfo(this.map, e.latlng);
    // });
    return this.map;
  }

  public setMap(mapIn: L.Map) {
    this.map = mapIn;
  }
  
  public initMap(target: string): L.Map {
    const map = L.map(target, {
      zoomControl: false,
      attributionControl: false,
      // crs: L.CRS.EPSG3857
    }).setView([42.896, 71.393], 12);

    this.currentLayer = this.openstreetmap.addTo(map);
    map.addLayer(this.editableLayers);

    const scaleControl = L.control.scale({
      position: 'bottomright',
      metric: true,
      imperial: false
    });
    map.addControl(scaleControl);

    this.setMap(map);
    map.on('layeradd', (e: any) => { this.getLayerMeasureResultArea(map); });
    return map;
  }

  public clearEditableLayers(): void {
    if (this.editableLayers.getLayers().length > 0) {
      this.editableLayers.clearLayers();
    }
  }

  public getDrawControls(): any {
    return this.drawControls;
  }

  public setDrawControls(drawControls: L.Control.Draw): void {
    this.drawControls = drawControls;
  }

  // public clearMap(): void {
  //   const map = this.getMap();
  //   map.eachLayer((layer) => {
  //     if (this.currentLayer !== layer) {
  //       map.removeLayer(layer);
  //     }
  //   });
  // }
  getLayerMeasureResultArea(map: L.Map): void {
    map.eachLayer((lyr: any) => {
      if (lyr.options.className === 'layer-measure-resultarea') {
        this.showMeasurements(lyr);
      }
    });
  }

  showMeasurements(lyr: any): void {
    if (!lyr) {
      return;
    }
    setTimeout(() => {
      lyr.showMeasurements();
    }, 100);
  }

}
