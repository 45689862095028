import {Language} from './language';

const siteSettings = {
  identity: null,
  language: Language.RUS,
  search: true,
  isClearAllLyrs: false,
  filter: [],
  isCoordinate: false,
  print: false
};

export default siteSettings;
