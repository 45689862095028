<div class="bottom-container mat-elevation-z4" fxLayout="row">
  <!-- language buttons:  -->
  <div fxLayout="row" class="lang-icons" fxFlex="50%" fxLayoutAlign="flex-start center" fxLayoutGap="5px">

    <button fxLayoutAlign="center center" (click)="selectLanguage(language.RUS)"
            class="lang-icons-ru" [ngClass]="{'activeRu': isActive(language.RUS)}">
    </button>
    <button fxLayoutAlign="center center" (click)="selectLanguage(language.KAZ)"
            class="lang-icons-kz" [ngClass]="{'activeKz': isActive(language.KAZ)}">
    </button>
<!--    <button fxLayoutAlign="center center" (click)="selectLanguage(language.ENG)"-->
<!--            class="lang-icons-en" [ngClass]="{'activeEn': isActive(language.ENG)}">-->
<!--    </button>-->

  </div>
  <div fxFlex="50%" fxLayoutAlign="end center" fxLayoutGap="5px" class="socBtns">
    <button fxLayoutAlign="center center" (click)="info()" title="{{'bottom.info' | translate }}"
            class="info-icon" [ngClass]="{'activeInfo': isActive(language.ENG)}">
<!--      <img src="assets/icons/sh-bottom/info.svg" alt="">-->
    </button>

    <button fxLayoutAlign="center center" (click)="contact()" title="{{'bottom.contact' | translate}}"
            class="contact-icon" [ngClass]="{'activeContact': isActive(language.ENG)}">
<!--      <img src="assets/icons/sh-bottom/phone.svg" alt="">-->
    </button>
    <!--<button fxLayoutAlign="center center"><img src="assets/icons/sh-bottom/ig.svg" alt=""></button>-->
    <!--<button fxLayoutAlign="center center"-->
    <!--(click)="goToLink('http://facebook.com/sharer.php?u=http://geoportal.taraz.kz/')"><img-->
    <!--src="assets/icons/sh-bottom/fb.svg" alt=""></button>-->

  </div>
  <!--  <div fxFlex="10%" fxLayoutAlign="flex-start center" fxLayoutGap="5px">-->
  <!--    <button id="closeLayers" fxLayoutAlign="center center" (click)="closeLayers()" class="clear-all-layers"><img-->
  <!--      src="assets/icons/sh-bottom/close_layers.svg"-->
  <!--      alt=""></button>-->
  <!--  </div>-->
</div>
