import {Injectable} from '@angular/core';
import {MapService} from './map.service';
import {Geometry} from 'geojson';
import * as L from 'leaflet';
import {ControlPosition} from 'leaflet';
import {BehaviorSubject, Observable} from 'rxjs';
import * as WKT from 'terraformer-wkt-parser';
import {GeomService} from './geom.service';
// import '../../../libs/leaflet/coordinates/leaflet.coordinates.min.js';
import '../../../libs/leaflet/grouped-layer-control/groupedlayercontrol.min.js';
import '../../../libs/leaflet/fullscreen/Control.FullScreen.js';
import '../../../libs/leaflet/coordinates/coordinates.js';
import '../../../libs/leaflet/locate-control/locate-control.js';
import '../../../libs/leaflet/ruler-control/ruler-control.js';
import '../../../libs/leaflet/measure-control/leaflet-measure.js';
import '../../../libs/leaflet/browser-print/leaflet.browser.print.js';
import '../../../libs/leaflet/heat/leaflet-heat.js';
// @ts-ignore
// @ts-ignore
import focusIcon from '../../../assets/icons/zoom-in-out/center_focus_weak.png';
import {Point} from 'geojson';

// @ts-ignore

@Injectable({
  providedIn: 'root',
})
export class MapControlsService {

  private editableSubject: BehaviorSubject<boolean>;
  public editable: Observable<boolean>;
  heatMapLayer;
  heatMapAccident;
  bbox = '';
  selectedObj;

  constructor(private mapSvc: MapService,
              private geomSvc: GeomService) {

    this.editableSubject = new BehaviorSubject<boolean>(false);
    this.editable = this.editableSubject.asObservable();
  }

  public initCoordinates(map: L.Map): void {
    // @ts-ignore
    L.control
      .coordinates({
        position: 'bottomright', // optional default "bootomright"
        labelTemplateLat: 'N: {y}', // optional default "Lat: {y}"
        labelTemplateLng: 'E: {x}', // optional default "Lng: {x}"
        useDMS: false, // optional default false
        useLatLngOrder: true, // ordering of labels, default false-> lng-lat
      })
      .addTo(map);
  }

  public getEditableValue(): boolean {
    return this.editableSubject.value;
  }

  public setEditableValue(value: boolean): void {
    this.editableSubject.next(value);
  }

  public initMousePosition(map: L.Map): void {
    L.control.coordinates({
      position: 'bottomright',
      useDMS: true,
      labelTemplateLat: 'N {y}',
      labelTemplateLng: 'E {x}',
      useLatLngOrder: true
    }).addTo(map);
  }

  public initLocateControl(map: L.Map): void {
    L.control.locate({
      position: 'topright',
      icon: focusIcon,
    }).addTo(map);
  }

  public initRuler(map: L.Map): void {

    const ruler = L.control.ruler({
      position: 'topright',
      lengthUnit: {
        factor: null,
        display: 'm',
        decimal: 0,
        label: 'Дистанция:'
      },
    });

    ruler.addTo(map);
  }

  public initScale(map: L.Map): void {
    L.control
      .scale({
        metric: true,
        position: 'bottomright',
        updateWhenIdle: false
      })
      .addTo(map);
  }

  public initMeasureControl(map: L.Map): void {
    const measureControl = L.control.measure({
      position: 'topright'
    });
    measureControl.addTo(map);
  }

  public initPrint(map: L.Map): void {
    // L.control.browserPrint({
    //   position: 'topright',
    //   printModes: ['Portrait', 'Landscape', 'Auto', 'Custom'],
    //   closePopupsOnPrint: false,
    //   printLayer: L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.{ext}', {
    //     attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    //     subdomains: 'abcd',
    //     minZoom: 1,
    //     maxZoom: 16,
    //     // ext: 'png'
    //   })
    // }).addTo(map);

    // @ts-ignore
    // L.control.browserPrint({
    //   title: 'Печать карты',
    //   documentTitle: 'Печать карты',
    //   closePopupsOnPrint: false,
    //   manualMode: false,
    //   printLayer: L.tileLayer('http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
    //     attribution: '',
    //     subdomains: 'geo.e-jambyl.kz',
    //     minZoom: 1,
    //     // maxZoom: 16,
    //     // ext: 'png'
    //   }),
    //   printModes: [L.control.browserPrint.mode().landscape('TABLOID VIEW', 'tabloid')], //
    //   position: 'topright'
    // }).addTo(map);
  }

  public initGroupedLayerControl(map: L.Map): void {
    const baseLayers = {
      Гибрид: this.mapSvc.googleHybridLayer,
      Схема: this.mapSvc.googleRoadLayer,
      OSM: this.mapSvc.osmLayer,
      Спутник: this.mapSvc.googleSat,
      whiteMap: this.mapSvc.whiteMap,
      openStreetMap: this.mapSvc.openstreetmap,
    };
    const options = {
      position: 'topright'
    };
    // @ts-ignore
    L.control.groupedLayers(baseLayers, {}, options).addTo(map);
  }

  public initFullscreen(map: L.Map): void {
    // @ts-ignore
    L.control
      .fullscreen({
        title: 'Показать во весь экран',
        titleCancel: 'Выход из полноэкранного режима'
      })
      .addTo(map);
  }

  public initDrawControl(
    drawOptions: L.Control.DrawOptions,
    controlPosition: ControlPosition = 'topleft'
  ): any {
    const CustomMarker = L.Icon.extend({
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      iconUrl: '../../../../assets/map/images/markers/marker-icon.png',
      shadowUrl: '../../../../assets/map/images/markers/marker-shadow.png'
    });
    const control: L.Control.DrawConstructorOptions = {
      position: controlPosition,
      draw: {
        polyline: drawOptions.polyline || false,
        polygon: drawOptions.polygon || false,
        rectangle: drawOptions.rectangle || false,
        circle: drawOptions.circle || false,
        circlemarker: drawOptions.circlemarker || false,
        marker: drawOptions.marker
          ? (drawOptions.marker.icon = new CustomMarker())
          : false
      },
      edit: null
    };
    return control;
  }

  public drawEditableObject(geomWkt: string): void {
    const geometry: Geometry | any = WKT.parse(geomWkt);
    geometry.coordinates.map(coordinates => {
      const coords = this.parseCoords(coordinates);

      const layer: any = this.geomSvc.fetchLayerOfGeometry(
        geometry.type,
        coords
      );
      if (layer) {
        this.mapSvc.editableLayers.addLayer(layer);
        this.mapSvc.getMap().fitBounds(layer.getBounds());
      }
    });
  }

  private parseCoords(coordinates: any[]): any {
    const coords = [...coordinates];
    if (typeof coords === 'object') {
      return coords.map((e) => this.geomSvc.swapLatAndLng(e));
    }
    return this.geomSvc.swapLatAndLng([coords[0], coords[1]]);
  }

  public initHead(map: L.Map, crimeData: any[]): void {


    const cfg = {
      // radius should be small ONLY if scaleRadius is true (or small radius is intended)
      // if scaleRadius is false it will be the constant radius used in pixels
      radius: 2,
      maxOpacity: .8,
      // scales the radius based on map zoom
      scaleRadius: true,
      // if set to false the heatmap uses the global maximum for colorization
      // if activated: uses the data maximum within the current map boundaries
      //   (there will always be a red spot with useLocalExtremas true)
      useLocalExtrema: true
    };

    // @ts-ignore
    this.heatMapLayer = L.heatLayer(crimeData);
    this.heatMapLayer.addTo(map);
  }

  removeHeatMap(map: L.Map): void {
    if (this.heatMapLayer) {
      map.removeLayer(this.heatMapLayer);
    }
  }

  public showSelectedPointObjOnMap(geom: any) {

    this.removeSelectedPointObjOnMap();
    const map = this.mapSvc.getMap();

    const geometry = WKT.parse(geom) as Point;
    const point = geometry.coordinates;
    const unprojPoint = L.Projection.SphericalMercator.unproject(L.point(point[0], point[1]));
    map.setView([unprojPoint.lat, unprojPoint.lng], 14);
  }

  public removeSelectedPointObjOnMap() {
    const map = this.mapSvc.getMap();
    if (this.selectedObj) {
      map.removeLayer(this.selectedObj);
    }
  }


  // hideMapControls(hide: boolean) {
  //   const controls = document.getElementsByClassName('leaflet-control');
  //   // tslint:disable-next-line: prefer-for-of
  //   for (let i = 0; i < controls.length; i++) {
  //     const item: any = controls[i];
  //     if (item.classList.contains('leaflet-control-scale')) {
  //       continue;
  //     }
  //     item.style.display = hide ? 'none' : 'block';
  //   }
  // }
}

declare module 'leaflet' {
  namespace control {
    function fullscreen(obj: { title: string, titleCancel: string }): any;

    function coordinates(obj: {
      position: string;
      useDMS: boolean;
      labelTemplateLat: string;
      labelTemplateLng: string;
      useLatLngOrder: boolean;
    }): any;

    function locate(obj: {
      position: string,
      icon: focusIcon
    }): any;

    function ruler(obj: {
      position: string,
      lengthUnit: {
        factor: number,
        display: string,
        decimal: number,
        label: string
      },
    }): any;

    function measure(obj: {
      position: string,
    }): any;

    // function browserPrint(obj: {
    //   // position: string,
    //   title: string;
    //   documentTitle: string;
    //   printLayer: any;
    //   closePopupsOnPrint: boolean;
    //   printModes: any[];
    //   manualMode: boolean;
    //   position: string;
    //   mode: (() => {
    //     statements
    //   });
    // }): any;
  }

  namespace browserPrint {
    function mode(): any;
  }
}


