<h2 mat-dialog-title></h2>
<mat-dialog-content class="mat-typography">
  <div *ngIf="siteSettings.language===language.KAZ">
    <p><strong>БАЙЛАНЫС</strong></p>
    <p>Тараз қаласының сәулет және қала құрылысы бөлімі&raquo; КММ&raquo;</p>
    <p><strong>Мекен-жайы:</strong> 080012 Тараз қ., М. Жунисалиев к-сі, 1 үй&nbsp;</p>
    <p><strong>Телефондары:</strong> 8 (7262) 45-54-97, 8 (7262) 43-64-78</p>
  </div>
  <div *ngIf="siteSettings.language===language.RUS || siteSettings.language===language.ENG">
    <p><strong>КОНТАКТЫ</strong></p>
    <p>КГУ &laquo;Отдел архитектуры и градостроительства г. Тараз&raquo;</p>
    <p><strong>Адрес</strong>: 080012 г. Тараз, ул. Жунисалиева М, Дом 1&nbsp;</p>
    <p><strong>Телефоны</strong>: 8 (7262) 45-54-97, 8 (7262) 43-64-78</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'bottom.close' | translate}}</button>
</mat-dialog-actions>
