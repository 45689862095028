import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  accessToken = ''; // accessToken = 'access_token';
  userId = 'usr_id';

  constructor(
    private http: HttpClient,
  ) { }

  // login(username: string, password: string): any {
  //   return this.http.post(`/auth/token`, { username, password });
  // }

  setToken(token: string): void {
    localStorage.setItem(this.accessToken, token);
  }

  getToken(): string {
    return localStorage.getItem(this.accessToken);
  }

  setUserId(userId: number): void {
    localStorage.setItem(this.userId, String(userId));
  }

  getUserId(): number {
    return Number(localStorage.getItem(this.userId));
  }
}
