import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-scale-dialog',
  templateUrl: './scale-dialog.component.html',
  styleUrls: ['./scale-dialog.component.scss']
})
export class ScaleDialogComponent implements OnInit {

  scale = 50000;

  constructor(private dialog: MatDialogRef<ScaleDialogComponent>) {
  }

  ngOnInit(): void {
  }

  ok(): void {
    this.dialog.close(this.scale);
  }
}
