<mat-drawer-container class="main-container" autosize >
  <mat-drawer #drawer class="left-sidenav mat-drawer-opened" [opened]="true" mode="side">
    <app-lsn-header></app-lsn-header>
    <div class="center-container">
      <router-outlet></router-outlet>
    </div>
    <app-sh-bottom></app-sh-bottom>
  </mat-drawer>

  <div class="right-sidenav-content">
    <div class="toggle-left-sidenav" fxLayoutAlign="center" (click)="drawer.toggle();toggleLeftSide()" [ngStyle]="{'margin-left.px': width}">
      <!--      [ngStyle]="{'margin-left.px': width}"-->
      <mat-icon class="toto" *ngIf="leftSidenavOpened" svgIcon="left-arrow" aria-hidden="false" aria-label="toggle button">
      </mat-icon>
      <mat-icon class="toto" *ngIf="!leftSidenavOpened" svgIcon="right-arrow" aria-hidden="false"
                aria-label="toggle button"></mat-icon>
    </div>
    <app-legend class="legend" [ngStyle]="{'margin-left.px': width}"></app-legend>
    <div class="zoom-controls" fxLayout="column">
      <app-zoom-in-out></app-zoom-in-out>
    </div>

    <div class="bottom-right-ctrls" fxLayout="row" fxLayoutAlign="flex-start flex-end">
      <app-map-layers class="map-layers"></app-map-layers>
    </div>
    <div id="mapid">
    </div>
  </div>

</mat-drawer-container>
