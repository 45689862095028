<div fxLayout="column" class="map-lyrs-wrapper" *ngIf="isMapLyrsVisible">
  <div fxLayoutAlign="center center" class="hso-container hybrid" (click)="swtichLayer('hybrid')"><span [translate]="'map.hybrid'">Гибрид</span>
  </div>
  <div fxLayoutAlign="center center" class=" hso-container scheme" (click)="swtichLayer('scheme')"><span [translate]="'map.schema'">Схема</span>
  </div>
  <div fxLayoutAlign="center center" class="hso-container osm" (click)="swtichLayer('osm')"><span [translate]="'map.osm'">ОСМ</span></div>
  <div fxLayoutAlign="center center" class="hso-container googleSat" (click)="swtichLayer('googleSat')"><span [translate]="'map.googleSat'">Спутник</span></div>
  <div fxLayoutAlign="center center" class="hso-container white" (click)="swtichLayer('whiteMap')"><span>whiteMap</span></div>
  <div fxLayoutAlign="center center" class="hso-container street" (click)="swtichLayer('openStreetMap')"><span>openStreet</span></div>
</div> <!-- map-layers names -->

<div class="map-layers-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px"
  (click)="showLegendNames()">
  <span><a style="text-decoration:none" [translate]="'map.view'">Вид</a></span>
  <img *ngIf="isMapLyrsVisible" src="assets/icons/arrow-down.svg" alt="Arrow Down Icon">
  <img *ngIf="!isMapLyrsVisible" src="assets/icons/arrow_up.svg" alt="Arrow Up Icon">
</div> <!-- map-layers button itself -->
