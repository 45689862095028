import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedModule} from '../shared/shared.module';
import {LegendComponent} from './legend/legend.component';
import {MapLayersComponent} from './map-layers/map-layers.component';
import {ZoomInOutComponent} from './zoom-in-out/zoom-in-out.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ScaleDialogComponent} from './scale-dialog/scale-dialog.component';

@NgModule({
  declarations: [LegendComponent, MapLayersComponent, ZoomInOutComponent, ScaleDialogComponent],
  imports: [CommonModule, SharedModule, TranslateModule, NgxSpinnerModule],
  exports: [LegendComponent, MapLayersComponent, ZoomInOutComponent],
  providers: [TranslateService],
  entryComponents: [ScaleDialogComponent]
})
export class MapControlsModule {
}
