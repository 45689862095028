import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {environment} from '../../../environments/environment';
import {ILayerGroup} from '../models';
import {Layer} from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class LayersApiService {
  currentLayerGroupId$ = new BehaviorSubject<ILayerGroup>(null);
  // public layerByShortName = new Subject();
  // public observerShortName$ = this.layerByShortName.asObservable();

  // public layerByShortName2 = new Subject();
  // public observerShortName2$ = this.layerByShortName2.asObservable();

  // public layerByShortName3 = new Subject();
  // public observerShortName3$ = this.layerByShortName3.asObservable();

  public layerByShortNamePdp = new Subject();
  public observerShortNamePdp$ = this.layerByShortNamePdp.asObservable();

  public layerByShortNamePdpTwo = new Subject();
  public observerShortNamePdpTwo$ = this.layerByShortNamePdpTwo.asObservable();

  public layerByShortNameGp = new Subject();
  public observerShortNameGp$ = this.layerByShortNameGp.asObservable();

  // public layerByShortNameRegions = new Subject();
  // public observerShortNameRegions$ = this.layerByShortNameRegions.asObservable();
  private layer: Layer;

  constructor(
    private http: HttpClient,
  ) {
  }


  // public getLayersByShortName3() {
  //   return this.http.get(`${environment.api}/open/short_names_gp_region`);
  // }

  public getLayersByShortNamePdp() {
    return this.http.get(`${environment.api}/open/pdp_short_names`);
  }

  public getLayersByShortNamePdpTwo() {
    return this.http.get(`${environment.api}/open/pdp_region_short_names`);
  }

  public getLayersByShortNameGp() {
    return this.http.get(`${environment.api}/open/gp_region_short_names`);
  }


  // public setDataToLayerShortName(data: any) {
  //   if (data) {
  //     this.layerByShortNameRegions.next(data);
  //   }
  // }

  // public setDataToLayerShortName2(data: any) {
  //   if (data) {
  //     this.layerByShortName2.next(data);
  //   }
  // }

  // public setDataToLayerShortName3(data: any) {
  //   if (data) {
  //     this.layerByShortName3.next(data);
  //   }
  // }

  public setDataToLayerShortNamePdp(data: any) {
    if (data) {
      this.layerByShortNamePdp.next(data);
    }
  }

  public setDataToLayerShortNamePdpTwo(data: any) {
    if (data) {
      this.layerByShortNamePdpTwo.next(data);
    }
  }

  public setDataToLayerShortNameGp(data: any) {
    if (data) {
      this.layerByShortNameGp.next(data);
    }
  }

  getLayerThemesPermissions(userId: number): any {
    // return this.http.get(`${environment.api}/permissions/themes?userId=` + userId);
    return this.http.get(`${environment.api}/permissions/themes/open`);
  }

  getLayerGroupsByThemeId(themeId: number): any {
    if (!themeId) {
      return;
    }
    return this.http.get(`${environment.api}/layers/groups?themeId=${themeId}`);
  }

  getLayersByLayerGroupId(layerGroupId: number): any {
    if (!layerGroupId) {
      return;
    }
    return this.http.get(`${environment.api}/layers?layerGroupId=${layerGroupId}`);
  }

  getLayerObjects(layerName: string, body: object = {}, params: string = ''): any {
    if (!layerName) {
      return;
    }
    const paramsUrl = params && params !== '' ? params : '';
    const url = `${environment.api}/layers/${layerName}/objects/filter` + paramsUrl;
    return this.http.post(url, body);
  }

  getLayerObject(layerName: string, objectId: number, geoserverId: number): any {
    if (!layerName || !objectId) {
      return;
    }
    const url = `${environment.api}/layers/${layerName}/objects/${objectId}?geoserverId=${geoserverId}`;
    return this.http.get(url);
  }

  getLayerAttributes(layerName: string, geoserverId: number): any {
    if (!layerName) {
      return;
    }
    return this.http.get(`${environment.api}/layers/${layerName}/attributes?geoserverId=${geoserverId}`);
  }

  getLayerInfo(url): Observable<any> {
    return this.http.get<any>(url);
  }

  setLayer(layer: Layer): void {
    this.layer = layer;
    // this.currentLayerGroupId$.next(layer);
  }

  getLayer(): any {
    return this.layer;
  }

  // public getLayersByShortName() {
  //   return this.http.get(`${environment.api}/open/districts`);
  // }

  // public getLayersByShortName2() {
  //   return this.http.get(`${environment.api}/open/short_names_region`);
  // }


}
