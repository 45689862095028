import {Injectable} from '@angular/core';

import * as L from 'leaflet';
import {GEOMETRYTYPES} from '../constants/geometry-types';

@Injectable({
  providedIn: 'root'
})
export class GeomService {

  constructor() {
  }

  getDefaultMarker(): any {
    return L.Icon.extend({
      options: {
        iconUrl: '../../../assets/markers/default/marker-icon.png',
        iconRetinaUrl: '../../../assets/markers/default/marker-icon-2x.png',
        shadowUrl: '../../../assets/markers/default/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -32],
        shadowSize: [41, 41]
      }
    });
  }

  animatedZoom2Point(map: L.Map, coors: [number, number], zoomNumber: number, duration: number): void {
    map.setView(coors, zoomNumber, {
      animate: true,
      duration
    });
  }

  fetchLayerOfGeometry(geometryType: string, coords: any[]): any {
    const geomType = geometryType.toUpperCase();
    switch (geomType) {
      case GEOMETRYTYPES.POLYGON:
      case GEOMETRYTYPES.MULTIPOLYGON:
        return L.polygon(coords);
      case GEOMETRYTYPES.LINESTRING:
      case GEOMETRYTYPES.MULTILINESTRING:
        return L.polyline(coords);
      case GEOMETRYTYPES.POINT:
        return L.point(coords[0], coords[1]);
      default:
        return null;
    }
  }

  swapLatAndLng(latlngs: number[]): any {
    return latlngs.reverse();
  }
}
