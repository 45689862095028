import {Component, ElementRef, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import * as L from 'leaflet';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {SvgIconsService} from '../shared/services/svg-icons.service';
import {MapService} from '../shared/services/map.service';
import {AuthService} from './services/auth.service';
import {MapControlsService} from '../shared/services/map-controls.service';
import {LayersManagerService} from '../shared/services/layers-manager.service';
import siteSettings from '../shared/config/site-settings';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit, OnDestroy {
  title = 'esqo-public-web';
  leftSidenavOpened = true;
  width = 352;
  @ViewChild('drawer', {static: false}) drawer: ElementRef;
  map: L.Map;
  marker;
  private destroy$ = new Subject();

  constructor(
    private svgIconsService: SvgIconsService,
    private mapService: MapService,
    private authService: AuthService,
    private router: Router,
    private mapControlSvc: MapControlsService,
    private layersManagerService: LayersManagerService,
    private route: ActivatedRoute,
    private elementRef: ElementRef
  ) {
  }

  toggleLeftSide(): void {

    this.leftSidenavOpened = !this.leftSidenavOpened;

    setTimeout(() => {

      if (this.width === 352) {
        this.width = 0;
      } else {
        this.width = 352;
      }

      this.map.invalidateSize();
    }, this.leftSidenavOpened ? 150 : 100);

  }

  ngOnInit(): void {
    setTimeout(() => {
      const map: L.Map = this.mapService.initMap('mapid');
      this.map = map;

      this.mapControlSvc.initCoordinates(map);
      // this.mapControlSvc.initLocateControl(this.map);
      this.mapControlSvc.initPrint(this.map);
      this.mapControlSvc.initRuler(this.map);
      this.mapControlSvc.initMeasureControl(this.map);
      // this.mapControlSvc.initHead(this.map);
      this.mapService.getMap().addLayer(this.mapService.editableLayers);

      this.map.on('click', (e: any) => {

        this.layersManagerService.showGetFeatureInfo(this.map, e.latlng);

        if (this.marker) {
          this.map.removeLayer(this.marker);
        }

        if (siteSettings.isCoordinate) {
          this.marker = L.marker([e.latlng.lat, e.latlng.lng]);
          this.marker.addTo(map).bindPopup('<div style="padding:10px;">' + e.latlng.lat + ', ' + e.latlng.lng + '</div>').openPopup();
          // marker.addTo(map);
          siteSettings.isCoordinate = false;
        }
      });

      map.on('zoomend', () => {
        if (siteSettings.print) {
          this.mapService.zoomEnd.next('changed');
          siteSettings.print = false;
        }
      });

    }, 100);
    // this.autoLogin();
    const queryParams = this.route.snapshot.queryParams;
    if (!Object.keys(queryParams).length) {
      this.router.navigate(['/layer-themes']);
    }
  }

  metresPerPixel(): number {
    const southEastPoint = this.map.getBounds().getSouthEast();
    const northEastPoint = this.map.getBounds().getNorthEast();
    const mapHeightInMetres = southEastPoint.distanceTo(northEastPoint);
    const mapHeightInPixels = this.map.getSize().y;

    return mapHeightInMetres / mapHeightInPixels;
  }
  // autoLogin(): void {
  //   const queryParams = this.route.snapshot.queryParams;
  //   if (!Object.keys(queryParams).length) {
  //     this.router.navigate(['/layer-themes']);
  //   }
  // }

  // autoLogin(): void {
  //   this.authService
  //     .login(`${environment.username}`, `${environment.password}`)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(
  //       (user: UserToken) => {
  //         this.authService.setToken(user ? user.access_token : null);
  //         this.authService.setUserId(user ? user.userId : null);
  //         // console.log('token token: ', this.authService.getToken());
  //         const queryParams = this.route.snapshot.queryParams;
  //         if (!Object.keys(queryParams).length) {
  //           this.router.navigate(['/layer-themes']);
  //         }
  //       },
  //       () => {
  //         // this.pending = false
  //       }
  //     );
  // }


  ngOnDestroy(): void {
    this.destroy$.next();
  }
}

export interface UserToken {
  access_token: string;
  expires_in: number;
  jti: string;
  token_type: string;
  userId: number;
}
