import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable, pipe} from 'rxjs';
import {MapService} from '../../shared/services/map.service';
import {OpenStreetMapProvider} from 'leaflet-geosearch';
import {SearchResult} from 'leaflet-geosearch/src/providers/provider';
import {map} from 'rxjs/operators';
import {LayersManagerService} from '../../shared/services/layers-manager.service';
import {PortalApiService} from '../../shared/services/portal-api.service';

@Component({
  selector: 'app-lsn-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  control = new FormControl();
  streets: string[] = ['Champs-Élysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];
  filteredStreets: SearchResult[];
  provider1: any;

  searchIsGoogle = true;
  searchForm = new FormGroup({
    searchTextByGoogle: new FormControl(),
    searchTextByAddress: new FormControl(),
  });

  constructor(private mapService: MapService,
              private layersManagerService: LayersManagerService,
              private portalApiService: PortalApiService) {
    this.provider1 = new OpenStreetMapProvider();
  }

  ngOnInit(): void {

    this.control.valueChanges.subscribe(res => {
      this.search(res);
    });

    this.searchForm.get('searchTextByGoogle').valueChanges.subscribe(selectedValue => {
      this.search(selectedValue);
    });

  }

  changeSearchType(checked): void {
    this.searchIsGoogle = !checked;
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  search(searchText): void {
    if (searchText !== null && searchText !== '' && searchText.length > 3) {

      this.provider1.search({query: searchText}).then((res) => {
        this.filteredStreets = res.map(r => r);
      });
    }
  }

  selectedPoint(val): void {

    const result = this.filteredStreets.find(x => x.label === val);
    this.layersManagerService.addSearchMarker(result);
  }

  clearGoogleSearch(): void {
    this.searchForm.patchValue({searchTextByGoogle: ''});
    this.layersManagerService.clearSearchMarkerByAddressRegister();
  }

  searchByAddressRegister(): void {

    const {searchTextByAddress} = this.searchForm.value;
    if (searchTextByAddress !== null && searchTextByAddress !== '' && searchTextByAddress.length > 3) {
      this.portalApiService.searchByAddressRegister(searchTextByAddress).subscribe(res => {

        this.layersManagerService.addSearchMarkerByAddressRegister(res);

      });
    }
  }
}
